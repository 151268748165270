import React from 'react'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'

const Footer = () => {
  return (
    <Wrapper>
      <Title id='kontakt'>kontakt</Title>
      <ContactsSection>
        <Column>
          <Entry>Biuro Fundacji <br />Polskiej Rady Biznesu</Entry>
          <Entry>email: <a href='mailto: fundacja@prb.pl'>fundacja@prb.pl</a> </Entry>
          <Entry>tel. kom.: +48 602 617 613</Entry>
        </Column>
        <Column>
          <Entry>Adres korespondencyjny:</Entry>
          <Entry>
            Pałacyk Szucha
            <br />
            Al. Szucha 17
            <br />
            00-580 Warszawa
          </Entry>
          {/*<FacebookLink target='_blank' href='https://www.facebook.com/PolskaRadaBiznesu/'>*/}
          {/*  f*/}
          {/*</FacebookLink>*/}
        </Column>
        <Column>
          <Entry><a href='https://prb.pl/wp-content/uploads/2018/06/prb-presspack.zip'>Press pack</a></Entry>
          <Entry>
            <a href='https://prb.pl/wp-content/uploads/2022/03/PRB_informacja_prasowa_2022.pdf' rel='noreferrer' target='_blank'>
              Dla mediów
            </a>
          </Entry>
        </Column>
      </ContactsSection>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  gap: 70px;
  width: 100%;
  min-height: 280px;
  margin-top: 60px;
  font-size: 18px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.38px;
`
const Title = styled.div`
  font-size: 24px;
`
const ContactsSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;

  //@media (max-width: 1036px) {
  //  flex-direction: column;
  //}

  ${mobileCss(`
    width: unset;
    font-size: 14px;
    align-items: flex-start;
    flex-direction: column;
    gap: 25px;
    padding-bottom: 20px;
  `)};
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const Entry = styled.div`
  line-height: 24px;
  font-weight: 300;

  &:first-child {
    padding-bottom: 12px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
`
// const FacebookLink = styled.a`
//   width: 24px;
//   height: 24px;
//   padding: 2px;
//   text-align: center;
//   font-size: 20px;
//   text-decoration: none;
//   color: ${({ theme }) => theme.colors.primary};
//   border: 1px solid ${({ theme }) => theme.colors.primary};
//
//   &:hover {
//     color: ${({ theme }) => theme.colors.white};
//     border: 1px solid ${({ theme }) => theme.colors.white};
//   }
// `
