import React, { useState } from 'react'
import logo from 'assets/Fundacja-PRB-logo.png'
import styled, { css } from 'styled-components'
import { Drawer } from '@mui/material'

interface ButtonsWrapperProps {
  theme: {
    colors: {
      background: string;
      secondary: string;
    }
  }
}

const MobileNavbar = () => {
  
  const [open, setOpen] = useState(false)
  
  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }
  
  return (
    <NavbarWrapper>
      <Hamburger open={open} onClick={toggleOpen}>
        <Bar open={open} />
      </Hamburger>
      <LogoWrapper>
        <LogoElement src={logo} alt='Fundacja PRB Logo' />
        <Mission>
          <Statement>przedsiębiorczość</Statement>
          <Statement>odpowiedzialność</Statement>
          <Statement>zaangażowanie</Statement>
        </Mission>
      </LogoWrapper>
      <Drawer anchor='right' open={open}>
        <DrawerContent>
          <Hamburger open={open} onClick={toggleOpen}>
            <Bar open={open} />
          </Hamburger>
          <ButtonsWrapper>
            <NavbarButton href='#o_nas' onClick={toggleOpen}>o fundacji</NavbarButton>
            <NavbarButton href='#wladze' onClick={toggleOpen}>władze</NavbarButton>
            <NavbarButton href='#aktualnosci' onClick={toggleOpen}>aktualności</NavbarButton>
            <NavbarButton href='#sprawozdania' onClick={toggleOpen}>sprawozdania</NavbarButton>
            <NavbarButton href='#kontakt' onClick={toggleOpen}>kontakt</NavbarButton>
          </ButtonsWrapper>
        </DrawerContent>
      </Drawer>
    </NavbarWrapper>
  )
}

export default MobileNavbar

const NavbarWrapper = styled.div`
  padding-top: 40px;
  width: 100%;
  display: none;
  position: sticky;
  top: 0;
  right: 0;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1035px) {
    display: flex;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
`
const LogoElement = styled.img`
  width: 140px;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.colors.secondary};
`
const Mission = styled.div`

`
const Statement = styled.li`
  list-style: none;
  letter-spacing: 0.48px;
  padding-bottom: 3px;
`

const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 30px;
  row-gap: 10px;
`

const NavbarButton = styled.a`
  padding-bottom: 10px;
  background-color: transparent;
  border: 2px solid transparent;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }
`

const Hamburger = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 30px;
  cursor: pointer;
  z-index: 200;

  &::before,
  &::after,
  div {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.secondary};
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  &:first-of-type {
    align-self: flex-end;
  }

  ${({ open }) =>
          open &&
          css`
            &::before {
              transform: rotate(45deg) translateY(10px);
            }

            &::after {
              transform: rotate(-45deg) translateY(-10px);
            }

            div {
              opacity: 0;
            }
          `}
`
const Bar = styled.div<{ open: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: opacity 0.3s ease;
`
const DrawerContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
