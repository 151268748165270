import balasNoszczyk from 'assets/Balas-Noszczyk.png'
import kostrzewa from 'assets/Kostrzewa.png'
import krawczyk from 'assets/Krawczyk.png'

export const temporaryBoard = [
  {
    name: 'Beata Balas-Noszczyk',
    role: '(Członkini rady)',
    image: balasNoszczyk,
    shortBio: 'Partnerka kancelarii Hogan Lovells i kierownik jej Działu Instytucji Finansowych. Jest jednym z czołowych prawników ds. ubezpieczeń na rynku.',
    bio: 'Partnerka kancelarii Hogan Lovells i kierowniczka działu Instytucji Finansowych. Jest jedną z czołowych prawniczek ds. ubezpieczeń na rynku. Udziela porad w zakresie różnorodnych spraw ubezpieczeniowych, od transakcji typu M&A po zagadnienia związane z przestrzeganiem przepisów regulacyjnych. Absolwentka Uniwersytetu Warszawskiego i licencjonowana radczyni prawna. Doświadczona prawniczka w dziedzinach prawa handlowego, ubezpieczeniowego, bankowego, funduszy inwestycyjnych oraz funduszy emerytalnych.'
  },
  {
    name: 'Wojciech Kostrzewa',
    role: '(Członek rady)',
    image: kostrzewa,
    shortBio: 'Prezes Polskiej Rady Biznesu, przedsiębiorca, anioł biznesu. Udziałowiec i chairman Billon Group, właściciel i przewodniczący rad nadzorczych Wydawnictwa Pascal oraz Poland Investments.',
    bio: 'Prezes Polskiej Rady Biznesu, przedsiębiorca, anioł biznesu. Udziałowiec i chairman Billon Group, właściciel i przewodniczący rad nadzorczych Wydawnictwa Pascal oraz Poland Investments. Członek rady dyrektorów Stadler Rail, wiceprzewodniczący rady nadzorczej ERGO Hestia. Wcześniej prezes BRE Banku (mBank), członek zarządu Commerzbanku AG. Od 1999 związany z Grupą ITI jako członek rady dyrektorów, prezes, dyrektor generalny. W latach 2005–2017 przewodniczący i wiceprzewodniczący rady nadzorczej TVN.'
  },
  {
    name: 'Krzysztof Krawczyk',
    role: '(Członek rady)',
    image: krawczyk,
    shortBio: 'Dyrektor warszawskiego biura i partner CVC Capital Partners, wiodącej firmie z zakresu private equity i doradztwa inwestycyjnego na świecie.',
    bio: 'Dyrektor warszawskiego biura i partner CVC Capital Partners, wiodącej firmie z zakresu private equity i doradztwa inwestycyjnego na świecie. Należał do zarządów wielu spółek portfelowych CVC, m.in. Żabka, Stock Spirits Group, PKP Energetyka, D-Marin oraz AR Packaging. Wcześniej partner zarządzający Innova Capital. Członek zarządu Fundacji TVN oraz Valores, pierwszego funduszu filantropijnego w regionie CEE.'
  },
]
