export const Theme = {
  colors: {
    primary: '#c59b51',
    primaryLight: '#ecdec6',
    secondary: '#00265d',
    secondaryLight: '#99c3ff',
    white: '#FFF',
    black: '#000',
    footer: '#11242f'
  },
  fontFamily: 'Ubuntu, sans-serif',
}

// @ts-ignore
export const mobileCss = css => `
  @media (max-width: 768px) {
    ${css}
  }
`
