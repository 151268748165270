import React from 'react'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'

import BoardMemberCard from 'landing/components/BoardMemberCard'
import {temporaryBoard} from 'landing/tempSources/temporaryBoard'
import { functionaries } from 'landing/tempSources/functionaries'

const Board = () => {
  return (
    <Wrapper>
      <Title id='wladze'>Władze Fundacji</Title>
      <CouncilWrapper>
        <Functionaries>
          {temporaryBoard.map((member, index) => (
            <BoardMemberCard
              key={index}
              shortBio={member.shortBio}
              name={member.name}
              role={member.role}
              image={member.image}
              bio={member.bio}
            />
          ))}
        </Functionaries>
        <Functionaries>
          {functionaries.map((member, index) => (
            <BoardMemberCard
              key={index}
              shortBio={member.shortBio}
              name={member.name}
              role={member.role}
              image={member.image}
              bio={member.bio}
            />
          ))}
        </Functionaries>
      </CouncilWrapper>
    </Wrapper>
  )
}

export default Board

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: ${({ theme }) => theme.colors.secondary};
  letter-spacing: 0.38px;
`
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`
const CouncilWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`
const Functionaries = styled.div`
  display: flex;
  justify-content: space-around;

  ${mobileCss(`
    flex-direction: column;
    gap: 15px;
  `)}
`
