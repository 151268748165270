import React from 'react'
import styled from 'styled-components'

const ContactUs = () => {
  return (
    <Wrapper>
      <Title>Dane kontaktowe</Title>
      <SectionWrapper>
        <Column>
          <Entry>
            Fundacja Polskiej Rady Biznesu
            <br />
            Aleja Jana Chrystiana Szucha 17, 00-580
            <br />
            Warszawa, Polska
          </Entry>
          <Entry>
            <ContactLink href='mailto:fundacja@prb.pl'>fundacja@prb.pl</ContactLink>
            <Separator>|</Separator>
            <ContactLink href='https://www.fundacjaprb.pl' target='_blank'
                         rel='noopener noreferrer'>www.fundacjaprb.pl </ContactLink>
          </Entry>
          <Entry>
            KRS: 0000703640
            <br />
            NIP: 1132955601
            <br />
            Nr konta: 12 1140 1010 0000 3867 8100 1001
          </Entry>
        </Column>
      </SectionWrapper>
    </Wrapper>
  )
}

export default ContactUs

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 18px;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
`
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 300;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const Entry = styled.div`
  line-height: 30px;
`
const ContactLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.secondary};

  &:last-child {
    text-decoration: none;
  }
`
const Separator = styled.span`
  padding: 0 6px;
`
