import React from 'react'
import logo from 'assets/Fundacja-PRB-logo.png'
import styled from 'styled-components'

interface ButtonsWrapperProps {
  theme: {
    colors: {
      background: string;
      secondary: string;
    }
  }
}

const DesktopNavbar = () => {
  return (
    <NavbarWrapper>
      <LogoWrapper>
        <LogoElement src={logo} alt='Fundacja PRB Logo' />
        <Mission>
          <Statement>przedsiębiorczość</Statement>
          <Statement>odpowiedzialność</Statement>
          <Statement>zaangażowanie</Statement>
        </Mission>
      </LogoWrapper>
      <ButtonsWrapper>
        <NavbarButton href='#o_nas'>o fundacji</NavbarButton>
        <NavbarButton href='#wladze'>władze</NavbarButton>
        <NavbarButton href='#aktualnosci'>aktualności</NavbarButton>
        <NavbarButton href='#sprawozdania'>sprawozdania</NavbarButton>
        <NavbarButton href='#kontakt'>kontakt</NavbarButton>
      </ButtonsWrapper>
    </NavbarWrapper>
  )
}

export default DesktopNavbar

const NavbarWrapper = styled.div`
  padding-top: 40px;
  width: 80%;
  display: none;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1036px) {
    display: flex;
    gap: 80px;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
`
const LogoElement = styled.img`
  width: 200px;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.colors.secondary};
`
const Mission = styled.div`

`
const Statement = styled.li`
  list-style: none;
  letter-spacing: 0.48px;
  padding-bottom: 3px;
`

const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  gap: 35px;
`

const NavbarButton = styled.a`
  padding-bottom: 10px;
  background-color: transparent;
  border: 2px solid transparent;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }
`
