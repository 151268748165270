import React from 'react'
import AboutUs from 'landing/components/AboutUs'
import styled from 'styled-components'
import Navbar from 'landing/components/Navbar'
import Board from 'landing/components/Board'
import { mobileCss } from 'utils/theme'
import ContactUs from 'landing/components/ContactUs'
import ArticlesList from 'landing/components/ArticlesList'
import Reports from 'landing/components/Reports'
import Footer from 'landing/components/Footer'
import fundacja from 'assets/Palac_4-min (1).png'

const Landing = () => {
  
  return (
    <>
      <LayoutWrapper>
        <Navbar />
        <LandingPic src={fundacja} alt='Fundacja PRB Logo'/>
        <ContentWrapper>
          <AboutUs />
          <Board />
          <ContactUs />
          <ArticlesList />
          <Reports />
        </ContentWrapper>
        <Footer />
        <a href='#nawigacja'>
          <StickyArrow />
        </a>
      </LayoutWrapper>
    </>
  )
}

export default Landing

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  gap: 60px;
  padding: 20px;

  ${mobileCss(`
      gap: 25px;
  `)}
`
const StickyArrow = styled.div`
  width: 25px;
  height: 25px;
  border-right: 3px solid ${({ theme }) => theme.colors.primary};
  border-top: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  transform: rotate(-45deg);
  position: fixed;
  bottom: 30px;
  right: 40px;
  cursor: pointer;
  transition: border-top-width ease-in 0.1s, border-right-width ease-in 0.1s;

  &:hover {
    border-right: 5px solid ${({ theme }) => theme.colors.primary};
    border-top: 5px solid ${({ theme }) => theme.colors.primary};
  }
`

const LandingPic = styled.img`
  width: 80%;
  margin-top: 20px;
`
