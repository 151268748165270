import React from 'react';
import styled from 'styled-components';
import { mobileCss } from 'utils/theme'

type BoardProps = {
  name: string
  role: string
  image: string
  shortBio: string
  bio: string
}

const BoardMemberCard: React.FC<BoardProps> = ({ name, role, image, shortBio, bio }) => {
  const hasRole = role || null
  
  return (
    <Wrapper>
      <Content>
        <ImageWrapper>
          <img src={image} alt={`zdjęcie ${name}`} />
        </ImageWrapper>
        <BioWrapperPlaceholder />
        <BioWrapper>
          <Bio>
            <Header>
              <Name>{name}</Name>
              {hasRole && <Role>{role}</Role>}
            </Header>
            <ShortBioText>{shortBio}</ShortBioText>
            <FullBioText>{bio}</FullBioText>
          </Bio>
        </BioWrapper>
      </Content>
    </Wrapper>
  )
}

export default BoardMemberCard

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 530px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease-in-out;

  ${mobileCss(`
    width: 100%;
  `)}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ImageWrapper = styled.div`
  img {
    width: 230px;
    height: 230px;
    object-fit: cover;

    ${mobileCss(`
    height: 100%;
    width: 100%;
  `)}
  }
`

const BioWrapperPlaceholder = styled.div`
  height: 300px;
`

const BioWrapper = styled.div`
  position: absolute;
  height: 300px;
  width: 100%;
  transition: height 0.3s ease-in-out;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  font-size: 15px;
  //overflow-y: auto;
  padding: 10px 0;

  ${Wrapper}:hover & {
    height: 530px;
    position: absolute;
  }
`

const Bio = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
`

const Header = styled.div`
  padding-bottom: 16px;
`

const Name = styled.div`
  font-size: 18px;
  font-weight: 700;
`

const Role = styled.span`
  font-style: italic;
  display: block;
  margin-top: 8px;
`

const BioText = styled.div`
  margin-top: 16px;
`

const ShortBioText = styled(BioText)`
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  ${Wrapper}:hover & {
    display: none;
  }
`

const FullBioText = styled(BioText)`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${Wrapper}:hover & {
    opacity: 1;
  }
`
