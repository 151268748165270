import React from 'react'
import styled from 'styled-components'
import { formatUrl } from 'utils/formatUrl'

type ArticleProps = {
  date: string
  title: string
  image: string
  content: string
  linkName: string
  link: string
}

const Article: React.FC<ArticleProps> = ({ date, title, image, content, linkName, link }) => {
  
  const hasLink = link && linkName
  
  return (
    <ArticleWrapper>
      <Date>{date}</Date>
      <Title>{title}</Title>
      <Image src={image} alt={title} />
      <Content dangerouslySetInnerHTML={{ __html: content.replace(/\\n\\n/g, '<br><br>') }} />
      {hasLink && <SourceLink href={formatUrl(link)} target='_blank'>{linkName}</SourceLink>}
    </ArticleWrapper>
  )
}

export default Article

const ArticleWrapper = styled.div`
  margin-bottom: 20px;
  //padding: 20px 0;
  letter-spacing: 0.38px;
`

const Date = styled.div`
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
`

const Title = styled.h2`
  font-size: 18px;
  letter-spacing: 0.38px;
  margin-bottom: 10px;
`
const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`

const Content = styled.p`
  margin-bottom: 15px;
  line-height: 1.35em;
`

const SourceLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
