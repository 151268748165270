import React from 'react'
import DesktopNavbar from 'landing/components/DesktopNavbar'
import MobileNavbar from 'landing/components/MobileNavbar'
import styled from 'styled-components'

const Navbar = () => {
  return (
    <Wrapper id='nawigacja'>
      <MobileNavbar />
      <DesktopNavbar />
    </Wrapper>
  )
}

export default Navbar

const Wrapper = styled.div`

`
