import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from '@mui/material'
import { mobileCss } from 'utils/theme'

const AboutUs = () => {
  
  const [expanded, setExpanded] = useState(false)
  
  const toggleExpanded = () => {
    setExpanded((prev) => !prev)
  }
  
  return (
    <Wrapper>
      <Collapse in={expanded} collapsedSize={180}>
        <Title id='o_nas'>O Fundacji</Title>
        <Content>
          Fundacja Polskiej Rady Biznesu podejmuje i wspiera działania na rzecz wzmacniania polskiej gospodarki i
          społeczeństwa obywatelskiego. Pomaga organizacjom pozarządowym, ale też instytucjom administracji publicznej,
          w ich działaniach na rzecz budowy silnej gospodarki oraz sprawnego, obywatelskiego państwa. Kształtuje także i
          rozwija świadomość społeczeństwa w zakresie poszanowania godności oraz promuje ideę społeczeństwa
          obywatelskiego. Wspiera i inicjuje takie działania w edukacji, kulturze, sztuce, mediach i życiu publicznym.
        </Content>
        <Headline>Cele Fundacji</Headline>
        <Content>
          <ul>
            <li>Działania na rzecz wzmocnienia polskiej gospodarki i promocji idei przedsiębiorczości;</li>
            <li>Tworzenie przestrzeni dla współdziałania ludzi biznesu oraz ich udziału w debacie publicznej;</li>
            <li>Wspieranie organizacji społecznych i instytucji administracji publicznej w działaniu na rzecz budowy
              silnej
              gospodarki i sprawnego państwa;
            </li>
            <li>Upowszechnianie i ochrona wolności i praw człowieka oraz swobód obywatelskich, a także działania
              wspomagające rozwój demokracji;
            </li>
            <li>Kształtowanie i rozwój świadomości społeczeństwa w zakresie poszanowania godności jednostki ludzkiej;
            </li>
            <li>Działania polegające na promowaniu idei społeczeństwa obywatelskiego;</li>
            <li>Działalność na rzecz równych praw kobiet i mężczyzn;</li>
            <li>Działalności na rzecz dalszego pogłębiania procesu integracji europejskiej oraz rozwijania kontaktów i
              współpracy między społeczeństwami;
            </li>
            <li>Wspieranie oraz inicjowanie działań z zakresu sztuki, kultury i mediów dla upowszechniania praw i zasad
              godności ludzkiej.
            </li>
          </ul>
        </Content>
        <Headline>Fundacja realizuje cele poprzez</Headline>
        <Content>
          <ul>
            <li>Tworzenie i organizowanie programów edukacyjnych i aktywizujących w zakresie wzmocnienia polskiej
              gospodarki i promocji idei przedsiębiorczości;
            </li>
            <li>Inicjowanie i wspieranie działań na rzecz wzmocnienia polskiej gospodarki i promocji idei
              przedsiębiorczości narzędziami z obszarów kultury i sztuki, mediów, reklamy oraz nowoczesnych technologii;
            </li>
            <li>Organizowanie przedsięwzięć w przestrzeni publicznej mających na celu wzmocnienie polskiej gospodarki i
              promocji idei przedsiębiorczości, m.in. konferencji, szkoleń, kampanii społecznych, filmów, happeningów,
              konkursów;
            </li>
            <li>Prowadzenie działalności wydawniczej, edukacyjnej i kulturalnej;</li>
            <li>Współpracę z organizacjami partnerskimi;</li>
            <li>Współpracę z instytucjami państwowymi, samorządowymi, jak również organizacjami pozarządowymi, osobami
              fizycznymi, sektorem gospodarczym i środkami masowego przekazu w kraju i za granicą;
            </li>
            <li>Współpracę z zagranicznymi instytucjami kultury i oświaty;</li>
            <li>Promocję i organizację wolontariatu;</li>
            <li>Przyznawanie grantów przedsiębiorcom oraz organizacjom pozarządowym, których działalność ma na celu
              m.in.
              wzmocnienie polskiej gospodarki i promocję przedsiębiorczości;
            </li>
            <li>Przyznawanie stypendiów.</li>
          </ul>
        </Content>
      </Collapse>
      {expanded
        ? <Expander onClick={toggleExpanded}>Zwiń</Expander>
        : <Expander onClick={toggleExpanded}>Czytaj więcej</Expander>
      }
    </Wrapper>
  )
}

export default AboutUs

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  color: ${({ theme }) => theme.colors.secondary};

  ${mobileCss(`
     padding-top: 20px;
  `)}
`
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.38px;
  margin-bottom: 10px;
`
const Headline = styled.div`
  font-size: 24px;
  letter-spacing: 0.38px;
  margin-bottom: 10px;
  padding-top: 25px;
`
const Content = styled.div`
  font-size: 18px;
  line-height: 1.5rem;
  letter-spacing: 0.38px;
  font-weight: 300;
  padding-bottom: 10px;
`
const Expander = styled.div`
  padding-top: 10px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`
