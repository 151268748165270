import React, { ReactNode, useState } from 'react'
import { defaultValues } from 'utils/defaultValues'
import { modifyPath } from 'ramda'
import { ValuesTypes } from 'utils/valuesTypes'
import styled from 'styled-components'
import { User } from '@supabase/supabase-js'
import { mobileCss } from 'utils/theme'

export interface AppContextTypes {
  values: ValuesTypes
  formValueChange: (path: string, value: string | boolean) => void
  user: User | null,
  saveUserData: (user: User) => void
}

const initialValues = {
  values: defaultValues,
  formValueChange: (path: string, value: string | boolean) => {},
  user: null,
  saveUserData: () => {}
}

const AppContext = React.createContext<AppContextTypes>(initialValues)

export const AppCenterContextProvider = ({
  children
}: {
  children: ReactNode
}) => {
  const [values, setValues] = useState(defaultValues)
  const [user, setUser] = useState<User | null>(null)

  const formValueChange = (path: string, value: string | boolean) => {
    const pathSegments = path.split('.')

    setValues(prev => modifyPath(pathSegments, () => value, prev))
  }

  const saveUserData = (user: User) => {
    setUser(user)
  }

  return (
    <AppContext.Provider
      value={{
        formValueChange,
        values,
        user,
        saveUserData
      }}
    >
      <AppContentWrapper>
        {children}
      </AppContentWrapper>
    </AppContext.Provider>
  )

}


export default AppContext

const AppContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  // ${mobileCss(`
  //   width: 500px;
  // `)};
`
