import React from 'react'
import Article from './Article'

import { temporaryArticles } from 'landing/tempSources/temporaryArticles'
import styled from 'styled-components'

const ArticlesList = () => {
  return (
    <ArticlesWrapper>
      <Title id='aktualnosci'>Aktualności</Title>
      {temporaryArticles.map((article, index) => (
        <Article
          key={index}
          date={article.date}
          title={article.title}
          image={article.image}
          content={article.content}
          linkName={article.linkName}
          link={article.link}
        />
      ))}
    </ArticlesWrapper>
  )
}

export default ArticlesList

const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: ${({ theme }) => theme.colors.secondary};
`
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
`
