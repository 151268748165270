import durska from 'assets/Durska.png'
import kopyt from 'assets/Kopyt.png'
import olechowski from 'assets/Olechowski.png'

export const functionaries = [
  {
    name: 'Jacek Olechowski',
    role: '(Przewodniczący rady)',
    image: olechowski,
    shortBio: 'Partner i prezes Mediacap, do której należą m.in. Lifetube, Relatable, Freundschaft i IQS. Współzałożyciel mubi.pl, wspólnik Budlexu. Współtworzył m.in. MJM Holdings, rankomat.pl, Group One.',
    bio: 'Partner i prezes Mediacap, do której należą m.in. Lifetube, Relatable, Freundschaft i IQS. Współzałożyciel mubi.pl, wspólnik Budlexu. Współtworzył m.in. MJM Holdings, rankomat.pl, Group One. Członek Young Global Leaders. Przewodniczący rady Fundacji Polskiej Rady Biznesu, członek zarządu i przedstawiciel na Polskę fundacji Global Dignity. Członek rad nadzorczych: PWN SA, IAA Poland oraz Stowarzyszenia Przyjaciół Muzeum Narodowego w Warszawie.'
  },
  {
    name: 'Małgorzata Durska',
    role: '(Prezes zarządu)',
    image: durska,
    shortBio: 'Socjolożka i ekonomistka. Dyrektor Generalna Polskiej Rady Biznesu, Prezeska Fundacji PRB i wykładowczyni w Ośrodku Studiów Amerykańskich UW.',
    bio: 'Socjolożka i ekonomistka. Dyrektor Generalna Polskiej Rady Biznesu, Prezeska Fundacji PRB i wykładowczyni w Ośrodku Studiów Amerykańskich UW. Stypendystka programów Fulbrighta, Erasmus Sigma i Departamentu Stanu USA. Wieloletnia wicedyrektorka Instytutu Ameryk i Europy, koordynatorka polsko-amerykańskiego programu przedsiębiorczości, współzałożycielka i pierwsza dyrektorka stowarzyszenia Polish Professional Women’s Network. Tłumaczka książek z kultury biznesu.'
  },
  {
    name: 'Beata Kopyt',
    role: '(Sekretarz)',
    image: kopyt,
    shortBio: 'Absolwentka marketingu i zarządzania na Uniwersytecie Warszawskim i studiów podyplomowych CSR na Akademii Leona Koźmińskiego.',
    bio: 'Absolwentka marketingu i zarządzania na Uniwersytecie Warszawskim i studiów podyplomowych CSR na Akademii Leona Koźmińskiego. Współpracuje z Fundacją ONE DAY wspierającą usamodzielniająca się młodzież z pieczy zastępczej. Prywatnie miłośniczka hiszpańskiego stylu życia. Ciągle pod wrażeniem niegasnącej uprzejmości i dobrego humoru Hiszpanów. W przeszłości dyrektorka Kulczyk Foundation, a także dziennikarka „Rzeczpospolitej” i korespondentka gazety w Barcelonie.'
  }
]
