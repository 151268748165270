import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { Theme } from 'utils/theme'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AppCenterContextProvider } from 'AppContext'
import 'utils/global.css'
import { Toaster } from 'react-hot-toast'
import Cookies from 'components/Cookies'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Landing from 'landing/Landing'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  // VIP routes
  {
    path: '/',
    element: <Landing />
  },
  // {
  //   path: '/:code/broszura/wszyscy',
  //   element: <VipBookletPreview />
  // },
  // {
  //   path: '/:code/podglad/:id',
  //   element: <SingleVipPreview />
  // },
  // {
  //   path: '/:code/edytuj',
  //   element: <EditVip />
  // },
  //
  // // Admin routes
  // {
  //   path: '/admin',
  //   element: <Login />
  // },
  // {
  //   path: '/admin/menu',
  //   element: <AdminVipList />
  // },
  // {
  //   path: '/admin/menu/podglad/:id',
  //   element: <AdminVipPreview />
  // },
  // {
  //   path: '/admin/menu/edytuj/:id',
  //   element: <AdminEditVip />
  // },
  // {
  //   path: '/admin/menu/broszura/wszyscy',
  //   element: <VipBookletPreview />
  // }
])

root.render(
  <ThemeProvider theme={Theme}>
    <Toaster />
    <AppCenterContextProvider>
      <RouterProvider router={router} />
      <div id='modal-portal' />
      <Cookies />
    </AppCenterContextProvider>
  </ThemeProvider>
)
